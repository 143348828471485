.grid {
  width: 100%;
  margin-bottom: $unit;
  .row, &.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &.bordered {
    border: 1px solid #eee;
    border-radius: 4px;
  }


  &.striped {
    .row:nth-child(even) {
      background: #fff;
    }
  }


  .col {
    &.right {
      text-align: right;
    }
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    &.middle {
      align-self: center;
      vertical-align: middle;
    }
  }

  .separate, &.separate {
    .col {
      padding: $unit/2 $unit;
    }
  }



  .w_1 { flex-basis: 6.25%; }
  .w_2 { flex-basis: 12.5%; }
  .w_3 { flex-basis: 18.75%; }
  .w_4 { flex-basis: 25%; }
  .w_5 { flex-basis: 31.25%; }
  .w_6 { flex-basis: 37.5%; }
  .w_7 { flex-basis: 43.75%; }
  .w_8 { flex-basis: 50%; }
  .w_9 { flex-basis: 56.25%; }
  .w_10 { flex-basis: 62.5%; }
  .w_11 { flex-basis: 68.75%; }
  .w_12 { flex-basis: 75%; }
  .w_13 { flex-basis: 81.25%; }
  .w_14 { flex-basis: 87.5%; }
  .w_15 { flex-basis: 93.75%; }
  .w_16 { flex-basis: 100%; }

  &.striped {
    .row {
      &:nth-child(even) {
        background: #f0f3f4;
      }
    }
  }
}



