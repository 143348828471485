.paginator {
  width: 100%;
  padding: $unit 0;
  text-align: center;

  li {
    display: inline-block;
    a {
      text-decoration: none;
      display: inline-block;
      padding: $unit/2 $unit;
      color: #1e90ff;
      &:hover {
        background: lighten(#1e90ff, 40%);
      }
    }

    &.active {
      a {
        background: #1e90ff;
        color: white;
        font-weight: bold;
      }
    }

    &.prev {
      float: left;
    }

    &.first {
      float: left;
    }

    &.next {
      float: right;
    }

    &.last {
      float: right;
    }
  }
}