.label-value {
  display: inline-block;
  margin-right: $unit;
  .label {
    display: inline-block;
    font-weight: bold;
  }

  .value {
    display: inline-block;
  }
}