.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fs1 { @include fs(1); }
.fs2 { @include fs(2); }
.fs3 { @include fs(3); }
.fs4 { @include fs(4); }
.fs5 { @include fs(5); }
.fs6 { @include fs(6); }
.fs7 { @include fs(7); }
.fs8 { @include fs(8); }

.fs-1 { @include fs(-1); }
.fs-2 { @include fs(-2); }
.fs-3 { @include fs(-3); }


.fc-red {
  color: #e74c3c;
}

.fc-blue {
  color: #3498db;
}

.fc-green {
  color: #2ecc71;
}