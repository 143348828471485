.btn {
  display: inline-block;
  text-decoration: none;
  padding: $unit $unit*2;
  @include fs();
  &:hover {
    cursor: pointer;
  }

  &.btn-square {
    border-radius: 3px;
    background: white;
    color: #e84838;
    border: 2px solid #e84838;
    transition: all 0.4s;
    &:hover {
      background: #e84838;
      color: white;
    }
  }
}
.btn-square {
  display: inline-block;
  padding: 0.3em 1em;
  text-decoration: none;
  border-radius: 3px;
  background: white;
  color: #e84838;
  border: 2px solid #e84838;
  transition: all 0.4s;
  &:hover {
    background: #e84838;
    color: white;
  }
}

/*****************************************
 * 求人応募ボタン
 *****************************************/
.btn-entry {
  padding: $unit $unit*4;
  text-decoration: none;
  background: #E52A2A;
  color: white;
  font-weight: bold;
  border-radius: $unit*2;
  transition: all .4s;
  border: none;

  &:hover {
    background: #eb3b5a;
    cursor: pointer;
  }
}

.good-btn {
  padding: 3px 10px 4px 25px;
  background: white url(/assets/page/answer/good_icon.png) no-repeat 3px 3px;
  background-size: 18px;
  border-radius: 3px;
  /* background-position: 6px -23px; */
  font-size: 90%;
  border: 1px #ccc solid;
  color: #ccc;
  transition: all 0.3s;
  line-height: 1;
  &:hover, &.on {
    background: #2f6cb7 url(/assets/page/answer/on_good_icon.png) no-repeat 3px 3px;
    background-size: 18px;
    color: #fff;
  }
}

.count {
  line-height: 1;
  display: inline-block;
  width: 35px;
  height: 26px;
  font-size: 13px;
  padding: 3px 0 0 5px;
  background: url(/assets/page/answer/count_icon.png) no-repeat;
  color: #000;
  text-align: center;
}



