.app-form {



  input[type="text"], input[type="email"], input[type="password"],input[type="tel"],
  textarea, select {

    line-height: 1;
    @include fs();
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;

    &.form-error {
      border: 1px solid #f5c6cb;
    }

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
    }
  }


  .ctrl, .form-error {
    display: inline-block;
    padding: $unit/2;
    width: 100%;
  }

  .ctrl-label {
    font-weight: bold;
    border: 1px solid transparent;
  }

  .form-footer {
    text-align: right;
  }

  .error-message {
    color: #dc3545;
  }


  &.outline-default {
    border: 1px solid #efefef;
  }
}